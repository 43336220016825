import React, { useContext, useState } from 'react'
// import { createWorker } from 'tesseract.js';
// import Tesseract from 'tesseract.js';
// 
import './catRegistration.scss'
import { UploadFile } from '../../components/UploadFile/UploadFile';
import { debug, info, warn, error } from "../../services/logger.js";

// import { recognizePed } from '../../services/api';
import { CatContext, CatContextProvider } from '../../context/CatContext.js';
import { LanguageContext } from '../../context/langContext.js';
import { useParams } from 'react-router-dom';
import { sendToAI } from '../../services/api.js';
import AddCatFromAI from '../AddCatFromAI/AddCatFromAI.jsx';

const CatRegistration = () => {
    // const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts)
    // const catId = urlParts[urlParts.length - 2];
    const { id: catId } = useParams(); // Grabs catId from the URL
    // console.log("EditCatProfile | catId", catId)
    return (
        <CatContextProvider catId={catId}>
            <CatRegistrationChild catId={catId} />
        </CatContextProvider>
    );
}
const CatRegistrationChild = (catId) => {
    const [file1, setFile1] = useState(null)
    const [file2, setFile2] = useState(null)
    const [checkReg, setCheckReg] = useState(false)
    const [checkRegAndOwner, setCheckRegAndOwner] = useState(false)
    const [catNumber, setCatNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [regOption, setRegOption] = useState('')
    const options = ['reg', 'regOwner', 'owner']
    const [recognizeData, setRecognizedData] = useState([])
    // Function to download the image
    // async function downloadImage(url, path) {
    //   const response = await fetch(url);
    //   const buffer = await response.buffer();
    //   fs.writeFileSync(path, buffer);
    //   console.log('Image downloaded successfully!');
    // }
    debug('catRegistration.jsx | cat id to register', catId)
    const { data: cat } = useContext(CatContext);

    // // OCR function using direct Tesseract.recognize
    // const recognizeText = async (imageData) => {
    //   try {
    //     setLoading(true);
    //     const { data: { text } } = await Tesseract.recognize(imageData, 'eng+rus', {
    //       logger: (m) => console.log(m), // You can remove this if logging causes issues
    //     });
    //     setRecognizedText(text);
    //     console.log('Recognized Text:', text);
    //   } catch (error) {
    //     console.error('Error recognizing text:', error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };


    //UploadFile return us a file for the futher proccessing
    const handleOnChangeFile1 = (file) => {
        setFile1(file);
    }
    const handleOnChangeFile2 = (file) => {
        setFile2(file);
    }


    //   // Handle the recognition on button click
    //   const handleRecognition = async () => {
    //     if (file) {
    //       // const imageData = URL.createObjectURL(file);
    //       setLoading(true);
    //       const recognizedText = await recognizePed(file);
    //       setLoading(false);
    //       console.log('recognizedText', recognizedText)
    //       setRecognizedText(recognizedText)
    //     } else {
    //       console.log("No file selected for recognition.");
    //     }
    //   };

    // Usage
    // (async () => {
    //   // const imageUrl = 'https://example.com/pedigree-image.jpg';
    //   // const imagePath = file;
    //   // const imagePath = './pedigree.jpg';

    //   // Download the image
    //   // await downloadImage(imageUrl, imagePath);

    //   // Recognize text from the image
    //   const text = await recognizeText(file);

    //   // Process the text as needed
    //   console.log('Extracted text:', text);
    // })();
    // const handleContinueButton = () => {
    //     setRegOption()
    // }

    const handleSendPedigreeToAi = async () => {
        // const toSend = [file1, file2, cat.id]
        debug('CatRegistration.jsx > handleSendDocuments, cat.id ', cat.id)
        if (!file1) {
            alert('Please select a file to upload.');
            return;
        }

        try {
            console.log(file1)
            const result = await sendToAI(file1);
            // Check if result is already an object, if not, parse it
            // if (typeof result.recognizedText === 'string') {
            //     try {
            //         const parsedResult = JSON.parse(result.recognizedText);
            //         console.log('Parsed JSON:', parsedResult);
            //         setRecognizedText(parsedResult);
            //         return parsedResult;
            //     } catch (err) {
            //         throw new Error('Failed to parse JSON response');
            //     }
            // } else {
            //     return result; // It's already a valid JSON object
            // }
            console.log('Extracted Pedigree:', result.recognizedText);
            // setRecognizedData(result.recognizeText)

            // Step 1: Remove markdown backticks
            const cleanedText = result.recognizedText.replace(/^```json/, '').replace(/```$/, '').trim();
            console.log('cleaned', cleanedText)
            setRecognizedData(cleanedText)
            // Step 2: Parse the cleaned JSON string
            // try {
            //     const jsonData = JSON.parse(result.recognizedText);
            //     console.log('Parsed JSON:', jsonData);
            //     setRecognizedData(jsonData);
            // } catch (error) {
            //     console.error('Error parsing JSON:', error);
            // }
        } catch (error) {
            // alert('Failed to process the pedigree. Please try again.');
        }
    }

    const Reg = () => {
        return (
            <>
                <div className="add-cat-pedigree-recognition">
                    {/* <label htmlFor="cat_number">Cat number you want to register (if you have added it before)</label>
                    <input type="text" id="cat_number" /> */}
                    <UploadFile file={file1} onChangeFile={handleOnChangeFile1} fileId="file1" />

                    {/* for ownership purchase agreement */}
                    {/* {regOption !== 'reg' && regOption !== 'owner' &&
                        <UploadFile file={file2} onChangeFile={handleOnChangeFile2} fileId="file2" />
                    } */}

                    <button
                        className='primary-button-small'
                        // onClick={handleRecognition}
                        disabled={!file1 || loading}
                        onClick={handleSendPedigreeToAi}
                    >
                        {loading ? 'Processing...' : 'Send documents'}

                    </button>
                </div>
            </>
        )
    }

    const handleOptions = (index) => {
        // info('CatRegistration > Reg', 'Option is chosen', options[index])
        if (regOption === options[index]) {
            setRegOption('')
        } else {
            info('CatRegistration > Reg', `Option is chosen = ${options[index]}`)
            setRegOption(options[index])
        }
    }
    // console.log(cat)

    return (
        <>
            <h1>Registration for {cat?.cat_name_cattery_prefix && cat?.cat_name_cattery_prefix} {cat?.cat_name} with id {cat?.id} </h1>
            {/* <p>Choose one option</p>
            <div className="cat-registration">
                <h2>Cat and Ownership Registration - recomended!</h2>
                <span>Cat and Ownership Registration lets you:</span>
                <ul>
                    <li>
                        Full access to all breeding support and documents;
                    </li>
                    <li>
                        Issue kittens documnts including kittens pedigrees;
                    </li>
                    <li>
                        Sell kittens on the marketplace.
                    </li>
                </ul>

                <p>Just upload a cat pedigree and a cat purchase agreement. </p>
                <button className='secondary-button' onClick={() => handleOptions(1)}>Continue</button >
                {regOption === options[1] && <Reg />}
            </div > */}

            <div className="cat-registration">
                <h2>Cat Registration</h2>
                {/* <input
                    type="checkbox"
                    id="checkReg"
                    checked={checkReg}
                    onChange={() => setCheckReg(!checkReg)} />
                <label htmlFor="checkReg">Cat Registration</label>  */}
                {/* <span>Why do you need to register cat without an ownership?</span> */}
                <ul>
                    <li>
                        You have a registered female cat (with ownership) and you want to issue documents for kittens.
                        For this you need a male cat registered in catclub.
                    </li>
                    <li>
                        You want to register someone else cat - it's allowed in catclub!
                    </li>
                    {/* <li>
                        Prevent an online cat profile from deleting - Registered cats can't be deleted or edited
                    </li> */}
                </ul>
                <p>Just upload a cat pedigree</p>
                <button className='secondary-button' onClick={() => handleOptions(0)}>Continue</button >
                {regOption === options[0] && <Reg />}

                {/* <div className="add-cat-pedigree-recognition">
                    <label htmlFor="cat_number">
                        Cat number you want to register (if you have added it before)
                    </label>
                    <input type="text" id="cat_number" />
                    <UploadFile file={file1} onChangeFile={handleOnChangeFile1} fileId="file1" />
                    <button
                        className='primary-button-small'
                        // onClick={handleRecognition}
                        disabled={!file1 || loading}
                    >
                        {loading ? 'Processing...' : 'Send Pedigree'}
                    </button>
                    {recognizedText && (
                        <div className="recognized-text">
                            <h3>Extracted Text:</h3>
                            <p>{recognizedText}</p>
                        </div>
                    )}
                </div> */}
            </div>
            <div>
                <p>Recognized Text</p>
                <p>{recognizeData}</p>
            </div>
            <AddCatFromAI aidata={recognizeData}/>
            {/* <div className="cat-registration">
                <h2>Ownership Registration (for registered cats)</h2>
                <span>Ownership Registration benefits:</span>
                <ul>
                    <li>Your are the only who own this cat and its online profile;</li>
                    <li>
                        Nobody can become an online owner of your cat;
                    </li>
                    <li>
                        You can issue documents for kittens from the cat.
                    </li>
                    <li>
                        Nobody can see sensitive information about your cat.
                    </li>
                </ul>
                <p>Just upload a cat purchase agreement. </p>
                <button className='secondary-button' onClick={() => handleOptions(2)}>Continue</button >
                {regOption === options[2] && <Reg />}
                {/* {checkRegAndOwner &&
                    <>
                        <div className="add-cat-pedigree-recognition">
                            <label htmlFor="cat_number">Cat number you want to register (if you have added it before)</label>
                            <input type="text" id="cat_number" />
                            <UploadFile file={file2} onChangeFile={handleOnChangeFile2} fileId="file2" />
                            <UploadFile file={file3} onChangeFile={handleOnChangeFile3} fileId="file3" />

                            <button
                                className='primary-button-small'
                                // onClick={handleRecognition}
                                disabled={!file2 || !file3 || loading}
                            >
                                {loading ? 'Processing...' : 'Send Pedigree'}
                            </button>
                        </div>
                    </>
                } */}
            {/* </div> */}
        </>
        // <UploadFile selectedFile={selectedFile} onChangeFile={handleOnChangeFile} />
    )


}

// const FilePreviewDownload = ({ fileUrl, fileName, fileType }) => {
//   const [isImage, setIsImage] = useState(false);

//   // Check if the file type is an image for preview purposes
//   useEffect(() => {
//     const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
//     setIsImage(imageTypes.includes(fileType));
//   }, [fileType]);

//   return (
//     <div className="file-preview-download">
//       {isImage ? (
//         // Image preview if the file type is an image
//         <div>
//           <img
//             src={fileUrl}
//             alt={fileName}
//             className="file-preview"
//           />
//           <p className="preview-text">Preview of {fileName}</p>
//         </div>
//       ) : (
//         // Message for non-image files
//         <p className="preview-text">{fileName} is not an image file. Click below to download.</p>
//       )}
//       <a href={fileUrl} download={fileName} className="download-button">
//         Download {fileName}
//       </a>
//     </div>
//   );
// };

export default CatRegistration
