import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react'
// import { Link } from 'react-router-dom';
import { Clue } from '../../../components/ui/Clue/Clue';
import { CatContext } from '../../../context/CatContext';
// import catAva from '../../img/catAva.jpg';
import { faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
import './catProfileUp.scss';
import { DropdownMenu } from '../../../components/ui/DropdownMenu';
import { LanguageContext } from '../../../context/langContext';

//     const catProfileValue = {
//     metric,
//     pedigree,
//     // catId,
//     applicationStatus: state.applicationStatus,
//     prevOwner: state.prevOwner, handleDelete, handleMove,
// }
const CatProfileUp = (props) => {
    const { catProfileValue } = props;
    const prevOwner = catProfileValue?.prevOwner;
    const handleDelete = catProfileValue?.handleDelete;
    const handleMove = catProfileValue?.handleMove;
    const handleAddMate = catProfileValue?.handleAddMate;
    const error403 = catProfileValue?.error403;
    // console.log('CatProfileUp | catProfileValue = ', catProfileValue)
    const { translations, language } = useContext(LanguageContext)
    const { data } = useContext(CatContext);
    const catAva = '/images/catAva.jpg'
    // console.log('CatProfileUp | handleDelete = ', handleDelete)
    // console.log('CatProfileUp | data = ', data)
    const cat = data
    // console.log('cat.is_my_cat = ', cat.is_my_cat);
    if (!cat) {
        return null; // Return null or a fallback UI if cat is not available
    }
    return (
        <>

            {/* <h2 className='cat-profile__header'>
                {error403 ?
                    translations.cat_profile.cat_profile_public : translations.cat_profile.cat_profile}
            </h2> */}

            {/* {error403 ?
                <h2 className='cat-profile__header'> {translations.cat_profile.cat_profile_public}</h2>
                :
                <h2 className='cat-profile__header'> {translations.cat_profile.cat_profile}</h2>
            } */}
            <div className="cat-profile__header-block">

                {error403 ?
                    (
                        <>
                            <h2 className='cat-profile__header'> {translations.cat_profile.cat_profile_public}</h2>
                            {cat.registered !== 0 && <div className='cat-profile__registered'>Registered</div>}
                            {cat.is_my_cat === 1 && <div className='cat-profile__mycat'>Owned</div>}
                        </>
                    ) : (
                        <>
                            <h2 className='cat-profile__header'> {translations.cat_profile.cat_profile}</h2>
                            {cat.registered !== 0 && <div className='cat-profile__registered'>Registered</div>}
                            {cat.is_my_cat === 1 && <div className='cat-profile__mycat'>Owned</div>}

                            <>
                                <DropdownMenu
                                    catId={cat.id}
                                    which={'cat'}
                                    link1="Add a photo"
                                    linkUpdate="Update"
                                    linkDelete="Delete"
                                    cat={cat}
                                    handleMove={handleMove}
                                    handleDelete={handleDelete}
                                    handleAddMate={handleAddMate}
                                    prevOwner={prevOwner}
                                />
                            </>
                        </>
                    )
                }
            </div>

            <div className="cat-card">
                <div className='cat-card__left'>
                    <div className='catAva'>
                        <div className="catAva_relative">
                            <img className='catAva__img'
                                src={cat.cat_ava_url ? cat.cat_ava_url : catAva}
                                alt={cat.cat_ava_url ? ('Photo of ' + cat.cat_name) : ('catAva')} />

                            <div className="icon-mars-wrap">
                                {cat.sex === "male" ? (
                                    <FontAwesomeIcon icon={faMars} className="icon-mars" />
                                ) : (
                                    <FontAwesomeIcon icon={faVenus} className="icon-venus" />
                                )}
                            </div>
                            <div className="id-wrap">
                                <div className='card-id'>ID: {cat.id}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='cat-profile__right'>
                    <div className='cat-profile__right-title'>
                        <b>{cat?.cat_name_cattery_prefix} {cat?.cat_name} {cat?.cat_name_suffix}</b>
                    </div>
                    <div className='text'>
                        <div className='breed-highlight'>{language === 'en' ? cat?.breed_en : cat?.breed_ru} - {cat?.ems}</div>
                    </div>
                    <div className='text'>
                        <span className='text-label'>{translations.online_owner}</span>
                        <span className='text-main'>{cat?.first_name} {cat?.last_name}</span>
                        {/* <span><Clue text={'* owner not confirmed'} /></span> */}
                    </div>
                    <div className='text'><span className='text-label'>{translations.breeder}</span>
                        {cat?.breeder_id ? (
                            <span className='text-main'>{cat?.breeder_first_name} {cat?.breeder_last_name}</span>
                        ) : (
                            <span className='text-main'>{cat?.breeder_not_user}</span>
                        )
                        }
                    </div>
                    <div className='text'>
                        <span className='text-label'>{translations.birthday}</span>
                        <span className='text-main'>{cat.cat_birthdate}</span>
                    </div>
                    <div className='text'>
                        <span className='text-label'>{translations.color_ems}</span>
                        <span className='text-main'>{cat?.colorFullEms && cat?.colorFullEms}</span>
                        <div className='text'>
                            <span className='text-label'>{translations.color}</span>
                            <span className='text-main'>
                                {cat.main_eng_short && `${cat.main_eng_short}`}
                                {cat.gold && ` ${cat.gold?.toLowerCase()}`}
                                {cat.white && ` ${cat.white?.toLowerCase()}`}
                                {cat.tabby && ` ${cat.tabby?.toLowerCase()}`}
                                {cat.point && ` ${cat.point?.toLowerCase()}`}
                                {cat.eye && ` with ${cat.eye} eyes.`}
                            </span>
                        </div>
                    </div>
                    <div className='text'><span className='text-label'>{translations.chip}</span><span className='text-main'>{cat.chip}</span></div>
                    <div className='text'><span className='text-label'>{translations.registr_num}</span><span className='text-main'>{cat.reg_num && cat.reg_num}</span></div>
                    <div className='text'><span className='text-label'>{translations.description}</span> <span className='text-main'>{cat.description}</span></div>
                </div>

                {/* 
            {
                cat.registered === 0 ? (

                    <div className='button-container'>
                        {prevOwner.length >= 1 ?
                            (<button className="update" onClick={handleMove}>I'm not an owner. Delete it from my page. Move to a previous owner.</button>
                            ) : (
                                <button className="delete" onClick={handleDelete}>Delete</button>
                            )}
                        <Link to={`/cats/${cat.id}/update`}><button className="update">Update</button></Link>
                    </div>
                ) : (
                    <Link to={`/cats/${cat.id}/updateshort`}><button className="update">Update</button></Link>
                )

            }
            we don't show delite and update button when cat is registered !!! 
            */}

            </div >
        </>
    )
}

export default CatProfileUp
