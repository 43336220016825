import React from 'react'

import "./add.scss"
// import SearchCat from '../../components/SearchCat/SearchCat.jsx';
// import AddForm from './AddForm';
import AddCatSearch from '../AddCatSearch/AddCatSearch';
import AddCatWithColor from '../AddCatWithColor/AddCatWithColor';
// import AddCatPedigreeRecognition from '../AddCatPedigreeRecognition/AddCatPedigreeRecognition.jsx';

const Add = () => {

  return (
    <div className="add-cat-container">
      <AddCatSearch />
      <AddCatWithColor />
      {/* <AddCatPedigreeRecognition /> */}
    </div>
  )
}

export default Add
