import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './DocumentsTab.scss'
import { DialogModal } from './DialogModal';
import { Clue } from '../../../components/ui/Clue/Clue';
import { sendToRegistration } from '../../../services/api';
import { CatContext } from '../../../context/CatContext';
import { LanguageContext } from '../../../context/langContext.js';
import { debug, info, warn, error } from "../../../services/logger.js";


const DocumentsTab = (props) => {
    // console.log('DocumentsTab | props = ', props)
    const { catProfileValue } = props;
    console.log('DocumentsTab | catProfileValue = ', catProfileValue)
    debug('DocumentsTab | catProfileValue = ', catProfileValue)
    const { metric, pedigree, catId, applicationStatus, error403 } = catProfileValue
    const { data: cat } = useContext(CatContext);

    const [dialogOpen, setShowDialog] = useState(false);
    const [dialogOpenMetric, setShowDialogMetric] = useState(false);
    const [dialogOpenReg, setShowDialogReg] = useState(false);
    const { translations } = useContext(LanguageContext)

    //button to see a pedigree
    const onClickLook = () => {
        setShowDialog(true);
    };

    //Send cat to registration AND show a model window
    //Need to check if the application is exist in DB!!!
    const onClickReg = async () => {
        try {
            await sendToRegistration(catId, pedigree.id)//it makes a request to my backend=server
        } catch (error) {
            console.log(error)
        }
        setShowDialogReg(true);
        // window.location.reload() //to force the document to be fetced from the web server again.
        // Without it, when we delete a cat - it deletes in DB, but still in frontend.In future it better to do it using REDUX or some other managment tools
    };

    const onClickLookMetric = () => {
        setShowDialogMetric(true);
    };

    const onCloseDialog = () => {
        setShowDialog(false);
    };

    const onCloseDialogMetric = () => {
        setShowDialogMetric(false);
    };

    const onCloseDialogReg = () => {
        setShowDialogReg(false);
        window.location.reload() //to force the document to be fetced from the web server again.
    };

    const Metrica = () => {
        // console.log('metric.id', metric.id)
        if (!metric?.id) {
            return <Link
                to={{ pathname: `./metric/new` }}
                state={{ entityType: 'metric', isEdit: false }}
            >
                <button className='profile-button'>Add cat metric</button></Link>
        } else {

            return (
                <div className='metric'>
                    <div className='metric-header'>
                        <h3 className='inline-block'> {translations.metric}</h3>
                        <Link
                            className='profile-button'
                            // className='inline-block link'
                            to={{ pathname: `./metric/${metric?.id}` }}
                            state={{ entityType: 'metric', isEdit: true }}>
                            Edit
                        </Link>
                    </div>
                    {metric?.id ? (
                        <div className="metric-block">
                            <img
                                src={metric?.metric_img_url}
                                alt="cat_metric"
                                width='80px'
                                onClick={onClickLookMetric}
                                style={{ cursor: 'pointer' }}
                            />
                            <div className='metric-text'>
                                <div>
                                    <span className='text-label'> {translations.number}</span>
                                    <span className='text-main'>{metric.metric_num ? metric.metric_num : 'no metric number'}</span>
                                </div>
                                {/* <Link

                                    className='inline-block link'
                                    to={{ pathname: `./metric/${metric?.id}` }}
                                    state={{ entityType: 'metric', isEdit: true }}>
                                    {metric.metric_num ? metric.metric_num : 'no metric number'}
                                </Link> */}
                                {/* <Link className='inline-block link' to={`./edit-metric/${metric_id}`}>{metric_num}</Link> */}
                                <div>
                                    <span className='text-label'>{translations.issued_by}</span>
                                    <span className='text-main'>{metric.issued_by}</span>
                                </div>
                                <div>
                                    <span className='text-label'>{translations.system}</span>
                                    <span className='text-main'>{metric.abb}</span>
                                </div>
                            </div>
                        </div>
                    )
                        : (
                            <Link
                                to={{ pathname: `./metric/new` }}
                                state={{ entityType: 'metric', isEdit: false }}
                            >
                                <button className='profile-button'>Add a metric</button></Link>
                        )
                    }
                </div>
            )
        }
    }

    const Pedigree = () => {
        if (!pedigree?.id) {
            return (
                <React.Fragment>
                    <Link
                        to={{ pathname: `./pedigree/new` }}
                        state={{ entityType: 'pedigree', isEdit: false }}
                    >
                        <button className='profile-button'>Add pedigree</button></Link>
                    <Clue text={'To register a cat you should fill all the information about a cat before.'} />
                </React.Fragment>
            )

       //if application sent and NOT solved - show 'Application has been sent' 
        } else if (pedigree?.id > 0 && cat?.registered === 0 && cat?.breeder_first_name !== null && applicationStatus?.status !== 'solve' && applicationStatus.id > 0) {
            return (
                <React.Fragment>
                    <details open>
                        <summary>
                            <span className='big inline-block'> Pedigree</span>
                        </summary>
                        <div className="metric">
                            <div className='metric-text'>
                                <div className='inline-block'> {pedigree.num}</div>
                                <span className='inline-block gray '> Issued by</span>
                                <span className='inline-block'> {pedigree.issued_by}</span>
                                <span className='inline-block'> {pedigree.abb}</span>
                                <button className='profile-button' id="open" onClick={onClickLook}>Look</button>
                                <button className='profile-button margin-button' id="openReg" disabled={true} >Application has been sent</button>
                                <Clue text={'You can not edit the pedigree after sending to register'} />
                            </div>
                        </div>
                    </details>
                </React.Fragment>
            )

            //  Ready to register! 
            // Requirements: 
            //  1) all info about cat (including breeder_first_name !== null)
            //  2) all info about pedigree document (pedigrees_id - is truthy)
            //  3) NOT registered (!registered)
            //  4) NO current application (!applicationStatus.id)
        } else if (pedigree.id && (cat.breeder_first_name || cat.breeder_not_user) && !applicationStatus.id && !cat.registered) {
            return (
                <React.Fragment>
                    <details open>
                        <summary>
                            <span className='big inline-block'> Pedigree</span>
                        </summary>
                        <div className="metric">
                            <div className='metric-text'>
                                <Link className='inline-block link' to={{ pathname: `./pedigree/${pedigree.id}` }} state={{ entityType: 'pedigree', isEdit: true }}>
                                    {pedigree.pedigree_num ? pedigree.pedigree_num : 'no pedigree number'}
                                </Link>
                                <span className='inline-block gray '> Issued by</span>
                                <span className='inline-block'> {pedigree.issued_by}</span>
                                <span className='inline-block'> {pedigree.abb}</span>
                                <button className='profile-button' id="open" onClick={onClickLook}>Look</button>
                                {/* <Clue text={'To register a cat you should fill all the information about a cat before'} /> */}
                                <button className='profile-button margin-button' id="openReg" onClick={onClickReg}>Register a cat</button>
                            </div>
                        </div>
                    </details>
                </React.Fragment>
            )
            // if we added pedigree and NOT registered. 
            // if registered.
        } else if (pedigree.id > 0) {
            return (
                <div className='metric'>
                    {/* <summary>
                            <span className='big inline-block'> Pedigree</span>
                        </summary> */}
                    <div className='metric-header'>
                        <h3 className='inline-block'> {translations.pedigree}</h3>
                        {cat.registered === 0 ? (
                            <Link
                                className='profile-button'
                                // className='inline-block link'
                                to={{ pathname: `./pedigree/${pedigree?.id}` }}
                                state={{ entityType: 'pedigree', isEdit: true }}>
                                Edit
                            </Link>
                        ) : (
                            <button className='profile-button' disabled>Edit</button>
                        )
                        }
                    </div>
                    <div className="metric-block">
                        <img
                            src={pedigree?.pedigree_img_url}
                            alt="cat_pedigree"
                            width='80px'
                            onClick={onClickLook}
                            style={{ cursor: 'pointer' }}
                        />
                        <div className='metric-text'>
                            <div>
                                <span className='text-label'> {translations.number}</span>
                                <span className='text-main'>{pedigree.pedigree_num ? pedigree.pedigree_num : 'no pedigree number'}</span>
                            </div>
                            {/* <Link

                                    className='inline-block link'
                                    to={{ pathname: `./metric/${metric?.id}` }}
                                    state={{ entityType: 'metric', isEdit: true }}>
                                    {metric.metric_num ? metric.metric_num : 'no metric number'}
                                </Link> */}
                            {/* <Link className='inline-block link' to={`./edit-metric/${metric_id}`}>{metric_num}</Link> */}
                            <div>
                                <span className='text-label'>{translations.issued_by}</span>
                                <span className='text-main'>{pedigree.issued_by}</span>
                            </div>
                            <div>
                                <span className='text-label'>{translations.system}</span>
                                <span className='text-main'>{pedigree.abb}</span>
                            </div>
                            {cat.registered === 0 &&
                                <span className='clue-text'>To register a cat you should fill all the information about a cat before</span>
                            }
                            {/* <Clue text={'To register a cat you should fill all the information about a cat before'} /> */}
                        </div>
                    </div>
                </div>
            )
        }

        // REGISTERED registered === 1
        else {
            return (
                <React.Fragment>
                    {/* <details open>
                        <summary>
                            <span className='big inline-block'> Pedigree</span>
                        </summary>
                        <div className="metric">
                            <div className='metric-text'>
                                <div className='inline-block'> {pedigree.num}</div>
                                <span className='inline-block gray '> Issued by</span>
                                <span className='inline-block'> {pedigree.issued_by}</span>
                                <span className='inline-block'> {pedigree.abb}</span>
                                <button className='profile-button' id="open" onClick={onClickLook}>Look</button>
                                <p><strong className='registered'>Registered</strong><small> - the cat can not be updated or deleted</small></p>
                            </div>
                        </div>
                    </details> */}
                </React.Fragment>
            )
        }
    }

    if (error403) {
        return (
            <div>
                {error403}
            </div>
        )
    }

    return (
        <div className='cat-tab'>
            <Metrica />
            <Pedigree />
            {ReactDOM.createPortal(
                <DialogModal
                    dialogOpen={dialogOpenReg}
                    onCloseDialog={onCloseDialogReg}
                    num={'The registration application was sent'}
                    title={'Sent'} />,
                document.getElementById('modal-root')
            )}
            {ReactDOM.createPortal(
                <DialogModal
                    dialogOpen={dialogOpenMetric}
                    onCloseDialog={onCloseDialogMetric}
                    img={metric?.metric_img_url && metric?.metric_img_url}
                    num={metric?.metric_num}
                    title={'Metric'} />,
                document.getElementById('modal-root')
            )}
            {ReactDOM.createPortal(
                <DialogModal
                    dialogOpen={dialogOpen}
                    onCloseDialog={onCloseDialog}
                    img={pedigree?.pedigree_img_url && pedigree?.pedigree_img_url}
                    num={pedigree?.pedigree_num}
                    title={'Pedigree'} />,
                document.getElementById('modal-root')
            )}
        </div>
    )
}

// PedigreeTab.propTypes = {

// }

export default DocumentsTab
