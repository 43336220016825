//Example of data expected: 
//const hostURL = 'http://localhost:8800/api/upload';
//Here data come from Add.jsx AddUserPhoto.jsx component

// HandleUpload.js is a utility function that uploads a file (along with some additional data) 
// from the client side to a specified server endpoint. It handles several steps to ensure a robust upload process

import { debug, info, warn, error } from "../../services/logger";

// const hostUrl = '/upload';
// const fileP = await HandleUpload(state.selectedFile, `${BASE_URL}/upload`, currentUserId, entityType, +state.values.catId);

export const HandleUpload = async (selectedFile, hostUrl, userId, prefix, catId) => {
    info("HandleUpload.js | Starting upload...");

    // 1. Early return if no file is selected
    if (!selectedFile) {
        alert("Please select a file");
        return;
    };

    // 2. Client-side file-size check to avoid unnecessary POST calls
    const MAX_SIZE = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > MAX_SIZE) {
        alert("File size exceeds the 10MB limit. Please choose a smaller file.");
        return;
    }

    debug('HandleUpload | catId:', catId);
    debug('HandleUpload |', { 'uploading to: hostUrl': hostUrl, 'userId': userId, 'prefix': prefix })

    // 3. Create FormData and append required fields
    let formData = new FormData()
    formData.append('file', selectedFile, selectedFile.name);
    formData.append('userId', userId.toString());
    formData.append('prefix', prefix.toString());
    if (catId) formData.append('catId', catId.toString());

    // 4. Log the FormData content (helpful in debugging)
    //    Note: If you have large files, you may not want to log it.
    for (let [key, value] of formData.entries()) {
        debug(`HandleUpload | formData entry | ${key}: ${value}`);
    }

    // send to api/uload
    try {
        let response = await fetch(hostUrl, {
            method: 'POST',
            credentials: 'include', // This sends cookies with the request
            body: formData
        });

        // 5. Handle non-2xx responses
        if (!response.ok) {
            warn('HandleUpload.js', '!response.ok')
            let errorText = `Error ${response.status} ${response.statusText}`;

            try {
                // Attempt to parse the response body as JSON
                const errorData = await response.json();

                // If the server returned something like { error: "File size..." }:
                if (errorData && errorData.error) {
                    errorText = errorData.error;
                } else if (errorData && errorData.message) {
                    // In case the server uses a different field (e.g., { message: "Some error..." })
                    errorText = errorData.message;
                }
            } catch (parseError) {
                // If JSON parsing fails, stick to the default errorText
                console.warn("Failed to parse error response as JSON:", parseError);
            }

            // Throw a new Error containing the relevant message
            throw new Error(`Upload failed: ${errorText}`);
        }

        const data = await response.json();
        debug("HandleUpload | Upload success:", data);
        return data;
    } catch (err) {
        // 7. Catch and log any errors
        error("HandleUpload | Error during file upload:", err);
        throw err; // Re-throw to handle it at a higher level if needed
    }
}