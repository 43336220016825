import React, { useEffect } from "react";
import { useState } from "react"
import './uploadFile.scss';
import { faCamera } from "@fortawesome/free-solid-svg-icons";

// <UploadFile selectedFile={selectedFile} onChangeFile={handleOnChangeFile} />

export const UploadFile = ({ file, onChangeFile, fileId }) => {
    const [selectedFile, setSelectedFile] = useState(file);

    // Synchronize local `selectedFile` state with `file` prop
    useEffect(() => {
        setSelectedFile(file);
    }, [file]);

    // Save file as a state.
    const addImg = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            setSelectedFile(newFile);
            onChangeFile(newFile);
        } else {
            console.log('No file selected');
            setSelectedFile(null);
        }
    };
    // Save file as a state.
    // const addImg = (e) => {
    //     let file = e.target.files[0]
    //     // console.log(e.target.files[0])
    //     if (file !== undefined) {
    //         setSelectedFile(file);
    //         onChangeFile(file);
    //     }
    //     else {
    //         console.log('No file')
    //         setSelectedFile()
    //     }
    // }

    return (
        <div>
            <div className="upload-photo">
                <small>Accept only .jpeg, .jpg, .png, .gif</small>
                <label htmlFor={`InputFile-${fileId}`} className="upload-label">
                    <i className={faCamera}></i> Upload a photo
                </label>
                <input
                    type="file"
                    name="cat_photo"
                    accept="image/png, image/gif, image/jpeg, image/jpg, .jpg, .jpeg, .png, .gif"
                    id={`InputFile-${fileId}`} // Unique id for each file input
                    className="photo-input"
                    onChange={addImg} />
                {Boolean(selectedFile) && (
                    <div className="preview-container">
                        <img src={URL.createObjectURL(selectedFile)} alt="imgpreview" />
                    </div>)}
            </div>
        </div>
    )
}
